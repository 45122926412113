<template>
  <div ref="buttonRef"></div>
</template>

<script>
import useGoogleSignIn from "@/composables/useGoogleSignIn";

export default {
  props: {
    handleCredentialResponse: {
      type: Function,
      required: true,
    },
  },

  setup(props) {
    const buttonOptions = {
      type: "standard",
      theme: "filled_blue",
      size: "large",
      text: "signin_with",
      shape: "pill",
      logo_alignment: "left",
    };

    const { buttonRef } = useGoogleSignIn(
      buttonOptions,
      props.handleCredentialResponse
    );

    return {
      buttonRef,
    };
  },
};
</script>

<style scoped>
.button-auth-google__logo {
  width: 18px;
  height: auto;

  margin-right: 12px;
}

.button-auth-google__label {
  padding-right: 10px;
}
</style>
